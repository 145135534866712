import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Logos from "../components/logos";

const ContactPage = ({ data: { site } }) => {
    return (
        <Layout>
            <Helmet>
                <title>Contact — {site.siteMetadata.title}</title>
                <meta
                    name="description"
                    content={"Contact page of " + site.siteMetadata.description}
                />
            </Helmet>

            <div className="w-full mx-auto">
                <div className="py-10 sm:pt-16 lg:pt-8 lg:pb-8 lg:overflow-hidden bg-header">
                    <div className="mx-auto max-w-7xl px-4 lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div className=" sm:text-center px-4 lg:px-8 lg:text-left lg:flex lg:items-center">
                                <div className="lg:py-24">
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span className="text-base block font-semibold tracking-wide uppercase">Contact Us</span>
                                        <span className="pb-3 block text-white sm:pb-5">Get in touch</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white my-10 sm:my-0">


                <div className="relative mx-auto max-w-7xl px-4 lg:px-8">
                    <div className="relative md:bg-white md:p-6">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                <p>Our services have been developed to ensure that our clients receive technically accurate and authoritative support. We offer consultancy services in all aspects of electrical installations</p>

                            </div>
                            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">

                                <p>We also offer a partner package to support an organisation over a longer timeframe. Our services are not limited to the above. Please contact us and we will be happy to discuss your requirements.</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <Logos />
            <div className="relative bg-white">
                <div className="absolute inset-0">
                    <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
                </div>
                <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                    <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                        <div className="max-w-lg mx-auto">
                            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                                Get in touch
                            </h2>
                            <p className="mt-3 text-lg leading-6 text-gray-500">
                                Drop us a message
                            </p>
                            <dl className="mt-8 text-base text-gray-500">
                                <div className="mt-6">
                                    <dt className="sr-only">Phone number</dt>
                                    <dd className="flex">
                                        <svg
                                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                            />
                                        </svg>
                                        <span className="ml-3">+44 (01482) 572698</span>
                                    </dd>
                                </div>
                                <div className="mt-3">
                                    <dt className="sr-only">Mobile number</dt>
                                    <dd className="flex">
                                        <svg className="h-6 w-6 text-gray-400" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="#a1a1a9">
                                                <path d="m946.88 138.28c0-73.828-59.766-133.59-133.59-133.59h-426.56c-73.828 0-133.59 59.766-133.59 133.59v923.44c0 73.828 59.766 133.59 133.59 133.59h426.56c73.828 0 133.59-59.766 133.59-133.59zm-614.06 100.78h534.38v658.59h-534.38zm480.7 876.56h-427.03c-29.766 0-53.672-24.844-53.672-54.375v-83.906h534.38v83.906c0 29.531-23.906 54.375-53.672 54.375zm53.672-976.88v20.625h-534.38v-20.625c0-29.766 23.906-54.375 53.672-54.375h427.03c29.766 0 53.672 24.844 53.672 54.375z" />
                                                <path d="m657.19 1007.8h-114.38c-22.031 0-39.844 17.812-39.844 39.844s17.812 39.844 39.844 39.844h114.38c22.031 0 39.844-17.812 39.844-39.844s-17.812-39.844-39.844-39.844z" />
                                            </g>
                                        </svg>
                                        <span className="ml-3">07375 666381</span>
                                    </dd>
                                </div>
                                <div className="mt-3">
                                    <dt className="sr-only">Email</dt>
                                    <dd className="flex">
                                        <svg
                                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                            />
                                        </svg>
                                        <span className="ml-3">tb@timbensteadassociates.org</span>
                                    </dd>
                                </div>
                            </dl>

                        </div>
                    </div>
                    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                        <div className="max-w-lg mx-auto lg:max-w-none">
                            <form
                                method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact"
                                className="grid grid-cols-1 gap-y-6"
                            >
                                <input type="hidden" name="form-name" value="contact" />
                                <div>
                                    <label htmlFor="full_name" className="sr-only">
                                        Full name
                                    </label>
                                    <input
                                        type="text"
                                        name="full_name"
                                        id="full_name"
                                        autocomplete="name"
                                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        placeholder="Full name"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="email" className="sr-only">
                                        Email
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autocomplete="email"
                                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        placeholder="Email"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="phone" className="sr-only">
                                        Phone
                                    </label>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        autocomplete="tel"
                                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        placeholder="Phone"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="message" className="sr-only">
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="4"
                                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        placeholder="Message"
                                    ></textarea>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
};
export default ContactPage;
export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
